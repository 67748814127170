<template>
  <files-comments
    v-if="mode !== 'create'"
    :id="id"
    :source_id="source_id"
    wrap
    column />
  <div
    v-else
    class="violations-editor__notice">
    <r-icon
      :size="20"
      name="info"
      color-type="warning" />
    <r-text>
      Работа с этой вкладкой будет доступна после создания. Сохраните нарушение,
      чтобы прикрепить к нему файлы и комментарии.
    </r-text>
  </div>
</template>

<script>
import filesComments from '@/components/files-comments/files-comments'

export default {
  components: { filesComments },
  props: {
    mode: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    source_id: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
.violations-editor__notice {
  display: grid;
  grid-gap: 0.5rem;
  grid-auto-flow: column;
  background-color: var(--bg_containers);
  border-radius: var(--border_radius);
  padding: 0.5rem;
}
</style>
